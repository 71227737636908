<template>
  <Layout>
    <iframe
      id="remote_viewer"
      ref="remote_viewer"
      style="border: none;"
      frameborder="0"
      :src="src"
      crossorigin="anonymous"
      target="_parent"
      allow="camera *; geolocation *; microphone *; autoplay *"
      name="remote_viewer"
      width="100%"
      height="100%"
    ></iframe>
  </Layout>
</template>

<script>
import Layout from '@layouts/public.vue'
export default {
  name: 'RemoteAccessDesktop',
  components: { Layout },
  computed: {
    src() {
      return atob(this.$route.params.dest)
    },
  },
}
</script>

<style>
.embeddedFrame {
  visibility: visible;
  border: none;
  width: 100vw;
  height: 100vh;
}
</style>
